<template>
  <div class="container-fluid pt-3">
    <div class="gws_content_container p-3">
      <!-- title -->
      <div class="row g-3">
        <div class="col-md-12">
          <h1 class="text-center mb-0">{{ t("nav.stages") }}</h1>
          <div class="subtitle-name mb-3 text-center">{{ displayName }}</div>
        </div>
      </div>
      <!-- nav -->
      <div class="row g-3">
        <div class="col-md-12">
          <AddressNavigation :categories="address.categories" :id="$route.params.id"></AddressNavigation>
        </div>
      </div>
      <!-- add new -->
      <div class="gws_control_bar d-lg-flex justify-content-center align-items-center p-2 mt-3 mb-2">
        <button
          @click="addModal = !addModal"
          type="button"
          class="d-flex flex-row align-items-center justify-content-center btn btn-success p-0 px-2 me-2"
          :title="t('general.add')"
        >
          <svg class="" width="32" height="32" fill="currentColor">
            <use xlink:href="/assets/img/bootstrap-icons.svg#plus"></use>
          </svg>
          <div>{{ t("general.add") }}</div>
        </button>
      </div>
      <!-- edit -->
      <div class="row" v-for="stage in stages" :key="stage.id">
        <div class="col">
          <div class="card my-2">
            <div class="card-body">
              <div class="card-text">
                <div class="row g-3">
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-2 col-sm-6">
                    <label :for="'stage_name_' + stage.id" class="form-label">{{ t("general.name") }}</label>
                    <input v-model="stage.name" type="text" class="form-control" :id="'stage_name_' + stage.id" />
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-1 col-sm-3">
                    <label :for="'stage_capacity_seated_' + stage.id" class="form-label">{{
                      t("stage.capacity_seated")
                    }}</label>
                    <input
                      v-model="stage.capacity_seated"
                      type="number"
                      class="form-control"
                      :id="'stage_capacity_seated_' + stage.id"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-1 col-sm-3">
                    <label :for="'stage_capacity_standing_' + stage.id" class="form-label">{{
                      t("stage.capacity_standing")
                    }}</label>
                    <input
                      v-model="stage.capacity_standing"
                      type="number"
                      class="form-control"
                      :id="'stage_capacity_standing_' + stage.id"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-1 col-sm-3">
                    <label :for="'stage_width_' + stage.id" class="form-label">{{ t("stage.width") }}</label>
                    <input
                      v-model="stage.width"
                      type="number"
                      step=".01"
                      class="form-control"
                      :id="'stage_width_' + stage.id"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-1 col-sm-3">
                    <label :for="'stage_depth_' + stage.id" class="form-label">{{ t("stage.depth") }}</label>
                    <input
                      v-model="stage.depth"
                      type="number"
                      step=".01"
                      class="form-control"
                      :id="'stage_depth_' + stage.id"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-1 col-sm-3">
                    <label :for="'stage_height_' + stage.id" class="form-label">{{ t("stage.height") }}</label>
                    <input
                      v-model="stage.height"
                      type="number"
                      step=".01"
                      class="form-control"
                      :id="'stage_height_' + stage.id"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-1 col-sm-3">
                    <label :for="'stage_clear_height_' + stage.id" class="form-label">{{
                      t("stage.clear_height")
                    }}</label>
                    <input
                      v-model="stage.clear_height"
                      type="number"
                      step=".01"
                      class="form-control"
                      :id="'stage_clear_height_' + stage.id"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-1 col-sm-3">
                    <label :for="'stage_distance_to_load_' + stage.id" class="form-label">{{
                      t("stage.distance_to_load")
                    }}</label>
                    <input
                      v-model="stage.distance_to_load"
                      type="number"
                      step=".01"
                      class="form-control"
                      :id="'stage_distance_to_load_' + stage.id"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-1 col-sm-3">
                    <label :for="'stage_electricity_' + stage.id" class="form-label">{{
                      t("stage.electricity")
                    }}</label>
                    <textarea
                      v-model="stage.electricity"
                      class="form-control fz-xs"
                      :id="'stage_electricity_' + stage.id"
                      style="min-height: 160px"
                    />
                  </div>
                  <div class="d-flex flex-column justify-content-between align-items-center col-xl-2 col-sm-6">
                    <label :for="'stage_comment_' + stage.id" class="form-label">{{ t("general.comment") }}</label>
                    <textarea
                      v-model="stage.comment"
                      class="form-control fz-xs"
                      :id="'stage_comment_' + stage.id"
                      style="min-height: 160px"
                    />
                  </div>
                </div>
                <!-- controls -->
                <div class="row">
                  <div class="col-12 border-top mt-3 pt-3 d-flex justify-content-center align-items-center">
                    <button
                      @click="saveStage(stage)"
                      type="button"
                      class="btn btn-success btn-icon me-2"
                      :title="t('general.save')"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#check"></use>
                      </svg>
                      {{ t("general.save") }}
                    </button>
                    <button
                      @click="toggleDeleteModal(stage.id)"
                      type="button"
                      class="btn btn-danger btn-icon me-2"
                      :title="t('general.delete')"
                    >
                      <svg class="bi" width="20" height="20" fill="currentColor">
                        <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
                      </svg>
                      {{ t("general.delete") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Modal @close="toggleDeleteModal" :modalActive="delteActiveModal">
    <template v-slot:header>{{ t("stage.delete") }}</template>
    <template v-slot:body>
      <p>{{ t("stage.delete_confirm_inquire") }}</p>
    </template>
    <template v-slot:footer>
      <button @click="toggleDeleteModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
      <button @click="deleteStage" type="button" class="btn btn-danger">{{ t("general.remove") }}</button>
    </template>
  </Modal>
  <Modal @close="addModal = !addModal" :modalActive="addModal" modalCssClass="modal-xl">
    <template v-slot:header>{{ t("general.add") }} {{ t("general.stage") }}</template>
    <template v-slot:body>
      <div class="container-fluid">
        <div class="row g-3">
          <div class="col-12">
            <label for="stage_name_new" class="form-label">{{ t("general.name") }}</label>
            <input v-model="newStage.name" type="text" class="form-control" id="stage_name_new" />
          </div>
          <div class="col-sm-6">
            <label for="stage_capacity_seated_new" class="form-label">{{ t("stage.capacity_seated") }}</label>
            <input
              v-model="newStage.capacity_seated"
              type="number"
              class="form-control"
              id="stage_capacity_seated_new"
            />
          </div>
          <div class="col-sm-6">
            <label for="stage_capacity_standing_new" class="form-label">{{ t("stage.capacity_standing") }}</label>
            <input
              v-model="newStage.capacity_standing"
              type="number"
              class="form-control"
              id="stage_capacity_standing_new"
            />
          </div>
          <div class="col-sm-6">
            <label for="stage_width_new" class="form-label">{{ t("stage.width") }}</label>
            <input v-model="newStage.width" type="number" step=".01" class="form-control" id="stage_width_new" />
          </div>
          <div class="col-sm-6">
            <label for="stage_depth_new" class="form-label">{{ t("stage.depth") }}</label>
            <input v-model="newStage.depth" type="number" step=".01" class="form-control" id="stage_depth_new" />
          </div>
          <div class="col-sm-6">
            <label for="stage_height_new" class="form-label">{{ t("stage.height") }}</label>
            <input v-model="newStage.height" type="number" step=".01" class="form-control" id="stage_height_new" />
          </div>
          <div class="col-sm-6">
            <label for="stage_clear_height_new" class="form-label">{{ t("stage.clear_height") }}</label>
            <input
              v-model="newStage.clear_height"
              type="number"
              step=".01"
              class="form-control"
              id="stage_clear_height_new"
            />
          </div>
          <div class="col-sm-6">
            <label for="stage_distance_to_load_new" class="form-label">{{ t("stage.distance_to_load") }}</label>
            <input
              v-model="newStage.distance_to_load"
              type="number"
              step=".01"
              class="form-control"
              id="stage_distance_to_load_new"
            />
          </div>
          <div class="col-sm-6">
            <label for="stage_electricity_new" class="form-label">{{ t("stage.electricity") }}</label>
            <textarea v-model="newStage.electricity" class="form-control" id="stage_electricity_new" />
          </div>
          <div class="col-sm-12">
            <label for="stage_comment_new" class="form-label">{{ t("general.comment") }}</label>
            <textarea v-model="newStage.comment" class="form-control" id="stage_comment_new" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button @click="addModal = !addModal" type="button" class="btn btn-secondary">{{ t("general.cancel") }}</button>
      <button @click="createStage" type="button" class="btn btn-success">{{ t("general.save") }}</button>
    </template>
  </Modal>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useApiQuery } from "@/composables/useApiQueries";
import { useToast } from "vue-toastification";
import Modal from "@/components/Modal.vue";
import AddressNavigation from "@/components/navigation/Address.vue";
import { getDisplayName } from "@/composables/utilities";
const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const toast = useToast();
const currentUser = computed(() => store.getters.user);

// get address
const { result: address, query: getAddress } = useApiQuery({
  url: "addresses/" + route.params.id,
  pfn: (data) => {
    // find Venue equal to 1 in categories
    if (!data.categories.find((c) => c.Venue === 1)) {
      // redirect to address edit
      router.push({ name: "AddressEdit", params: { id: route.params.id } });
    }
    return data;
  },
});

// get stages
const { result: stages, query: getStages } = useApiQuery({
  url: "search/stages/byvenue",
  params: {
    params: {
      venue_id: route.params.id,
    },
  },
});

// create stage
const { query: postStageQuery, error: postError } = useApiQuery({
  method: "post",
});

// update stage
const { query: putStageQuery, error: putError } = useApiQuery({
  method: "put",
});

// delete stage
const { query: delStageQuery, error: delError } = useApiQuery({
  method: "delete",
});

async function getAll() {
  await Promise.all([getAddress(), getStages()]);
}

const displayName = computed(() => {
  return getDisplayName(address.value);
});

const addModal = ref(false);
const newStage = ref({
  name: "",
  capacity_seated: "",
  capacity_standing: "",
  width: "",
  depth: "",
  height: "",
  clear_height: "",
  distance_to_load: "",
  electricity: "",
  comment: "",
});
const createStage = async () => {
  const stage = {
    venue_id: route.params.id,
    created_by: currentUser.value.id,
    updated_by: currentUser.value.id,
  };
  Object.assign(stage, newStage.value);
  addModal.value = false;
  try {
    await postStageQuery({
      url: "stage",
      params: stage,
    });
    if (postError.value) {
      toast.error(t("error.errorOccured") + " " + postError.value.response.status);
    } else {
      toast.success(t("general.stage") + " " + t("general.successfully") + " " + t("general.added") + ".");
      await getStages();
    }
  } catch (error) {
    if (error.response && error.response.status == 401) {
      store.dispatch("logout");
      router.push({ name: "Login" });
    } else {
      toast.error(t("error.errorOccured") + " " + error.response.status);
    }
  }
};

const saveStage = async (stage) => {
  stage.updated_by = currentUser.value.id;
  delete stage.created_by;
  delete stage.created_at;
  delete stage.updated_at;
  try {
    await putStageQuery({
      url: "stage/" + stage.id,
      params: stage,
    });
    if (putError.value) {
      toast.error(t("error.errorOccured") + " " + putError.value.response.status);
    } else {
      toast.success(t("general.stage") + " " + t("general.successfully") + " " + t("general.edited") + ".");
    }
  } catch (error) {
    if (error.response && error.response.status == 401) {
      store.dispatch("logout");
      router.push({ name: "Login" });
    } else {
      toast.error(t("error.errorOccured") + " " + error.response.status);
    }
  }
};

const deleteStage = async () => {
  if (!selectedStageId.value) {
    return;
  }
  const id = selectedStageId.value;
  try {
    toggleDeleteModal();
    await delStageQuery({
      url: "stage/" + id,
    });
    if (delError.value) {
      toast.error(t("error.errorOccured") + " " + delError.value.response.status);
    } else {
      toast.success(t("general.stage") + " " + t("general.successfully") + " " + t("general.deleted") + ".");
    }
    await getStages();
  } catch (error) {
    if (error.response && error.response.status == 401) {
      store.dispatch("logout");
      router.push({ name: "Login" });
    } else {
      toast.error(t("error.errorOccured") + " " + error.response.status);
    }
  }
};

const selectedStageId = ref(null);
const delteActiveModal = ref(false);
const toggleDeleteModal = (id) => {
  if (id) {
    selectedStageId.value = id;
  } else {
    selectedStageId.value = null;
  }
  delteActiveModal.value = !delteActiveModal.value;
};

getAll();
</script>
